import axios, {AxiosRequestConfig, Method} from 'axios'
import {RequestResult} from '@/utils/interface'
import router from '../router/index'

axios.defaults.withCredentials = true // 允许携带cookie
// @ts-ignore
axios.defaults.crossDomain = true

export default class ApiBase {
  protected url: string = '';
  protected prefix: string = ''
  protected resultDataKey: string = 'data';
  static error = {
    401: '您还没有登录',
    403: '权限不足，禁止访问',
    429: '请求次数过多',
    404: '页面找不到了',
  }

  static getRequestErrorTips(status:number) {
    // @ts-ignore
    return ApiBase.error.hasOwnProperty(status) ? ApiBase.error[status] : '网络请求错误:' + status
  }

  protected getToken() {
    return localStorage.getItem('token')
  }

  public get<P, R>(api: string, data: P): Promise<RequestResult<R>> {
    return this.request(api, data, 'GET')
  }

  public post<P, R>(api: string, data: P): Promise<RequestResult<R>> {
    return this.request(api, data, 'POST')
  }

  public delete<P, R>(api: string, data: P): Promise<RequestResult<R>> {
    return this.request(api, data, 'DELETE')
  }

  public put<P, R>(api: string, data: P): Promise<RequestResult<R>> {
    return this.request(api, data, 'PUT')
  }

  public request<P, R>(api: string, data: P, method: Method): Promise<RequestResult<R>> {
    return new Promise((resolve, reject) => {
      const requestMain: AxiosRequestConfig = {
        method,
        url: this.url + this.prefix + api,
      }
      if (method === 'GET') {
        requestMain.params = data
      } else {
        requestMain.data = data
      }

      axios(requestMain)
        .then(res => {
          try {
            const result = res.data
            const statusCode = res.status
            if (statusCode !== 200) {
              return reject({
                code: statusCode,
                message: ApiBase.getRequestErrorTips(statusCode),
                data: result.data
              })
            }
            if (result.code !== 1) {
              if (result.code === 40026) {
                console.log('没登录')
                localStorage.removeItem('is_login')
                 reject({
                  code: result.code,
                  message: result.message ? result.message : ApiBase.getRequestErrorTips(result.code),
                  data: result.data
                })
                router.push('/user/login')
                return
              }
              return reject({
                code: result.code,
                message: result.message ? result.message : ApiBase.getRequestErrorTips(result.code),
                data: result.data
              })
            }

            return resolve(result)
          } catch (e) {
            return reject({
              code: -10000,
              message: e,
              data: ApiBase.getRequestErrorTips(-10000)
            })
          }
        })

        .catch(error => {
          if (error.response) {
            console.log(`1`)
            return reject({
              code: error.response.status,
              data: error.response.data,
              message: ApiBase.getRequestErrorTips(error.response.status)
            })
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(2);
            return reject({
              code: -10000,
              data: error.request,
              message: ApiBase.getRequestErrorTips(-10000)
            })
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(3);
            return reject({
              code: -10000,
              data: error.request,
              message: ApiBase.getRequestErrorTips(-10000)
            })
          }
        })

    })
  }

}
